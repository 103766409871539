import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Home of iParticle Labs.</p>
        <p style={{ width: "50%" }}>
          Check back soon for exciting research supporting common good in AI,
          Cloud Blockchain, Quantum Computing, and more.
        </p>
        <a
          className="App-link"
          href="mailto:info@iparticle.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@iParticle.io
        </a>
      </header>
    </div>
  );
}

export default App;
